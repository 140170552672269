import React, { useContext, useEffect } from 'react'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { FooterVisibilityContext } from '@root/src/context/FooterVisibilityContext'
import { ViewTemplateContext } from '@root/src/context/ViewTemplateContext'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { MainLayout } from '@cmp/site'
import styles from './ViewTemplate.module.scss'
import {
  AddressForm,
  CreditCardForm,
  OrderDetails
} from './Types'

export default () => {
  const types = {
    creditCard: CreditCardForm,
    address: AddressForm,
    order: OrderDetails
  }
  const { modify } = useContext(MenuTypeContext)
  const { modifyVisibility } = useContext(FooterVisibilityContext)
  const { viewType, category } = useContext(ViewTemplateContext)
  const { language } = useLanguage()

  const Cmp = types[viewType]

  if (!Cmp) return null

  useEffect(() => {
    modify('secondary', category, true)
    modifyVisibility(false)
  }, [])

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <Cmp />
      </MainLayout>
    </GlobalDataProvider>
  )
}
